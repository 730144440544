import React from 'react'
import Layout from '../components/Layout'
import Component from '../components/NotFound'

const Page = () => (
  <Layout>
    <Component />
  </Layout>
)

export default Page
