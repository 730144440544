import React from 'react'
import styled from 'styled-components'
import Background from '../Background'
import {
  DARKEST,
  LIGHTEST,
  PRIMARY_THIRD,
  SECONDARY,
  TERTIARY,
} from '../../constants/colors'
import { PHONE } from '../../constants/breakpoints'

const Container = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;

  @media screen and (max-width: ${PHONE}px) {
    height: 40vh;
  }
`

const Body = styled.section`
  position: absolute;
  right: 3rem;
  top: 40%;
  max-width: 500px;
  text-align: right;

  @media screen and (max-width: ${PHONE}px) {
    right: 1rem;
    top: 30%;
  }
`

const Heading = styled.h1`
  background-color: ${SECONDARY};
  color: ${DARKEST};
  display: inline;
  font-size: 5rem !important;

  &::after {
    content: '';
    display: block;
  }

  @media screen and (max-width: ${PHONE}px) {
    font-size: 2rem !important;
  }
`

const SubHeading = styled.h2`
  display: inline;
  background-color: ${TERTIARY};
  color: ${LIGHTEST};
  font-size: 280% !important;
  line-height: 150% !important;

  @media screen and (max-width: ${PHONE}px) {
    font-size: 2rem !important;
  }
`

const Component = () => (
  <Background
    filename="hero-welder-01.jpg"
    size="100%"
    width="100%"
    color={PRIMARY_THIRD}
  >
    <Container>
      <Body>
        <Heading>Oh dear!</Heading>
        <SubHeading>We can&rsquo;t find what you are looking for.</SubHeading>
      </Body>
    </Container>
  </Background>
)

export default Component
